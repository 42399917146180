@import '../global';

.e_nav_wrapper{
    
	background: linear-gradient(90deg, #37963a 0.41%, #00c97c 140.38%);
    a{
        text-decoration: none;
        width: max-content;
        &:hover{
            text-decoration: none !important;
        }
    }
    .e_nav_container{
        overflow-x: auto;
        .e_nav_content{
            min-width: 840px;
        }
    }
    .e_nav_item{
        font-weight: 500;
        font-size: $size-16;
        line-height: $size-20;
        padding: 10px 16px;
        color: #fffd;
        background-color: #fff0;
        transition: 0.3s ease-in;
        border-radius: 100px;
        .e_nav_icon{
            width: 20px;
            height: 20px;
            path{
                fill: #fffc;
                transition: 0.3s ease;
            }
        }
        &:hover{
            color: $white;
            .e_nav_icon{
                path{
                    fill: $white;
                }
            }
        }
        &.e_nav_item_active{
            background: $item_highlight_bg;
            color: $white;
            .e_nav_icon{
                path{
                    fill: $white;
                }
            }
        }
    }
}