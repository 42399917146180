/*-------------------------------------------------------------------
Fonts import, Typography

-------------------------------------------------------------------*/

@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Black.ttf");
  font-weight: 900;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-ExtraBold.ttf");
  font-weight: 800;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Bold.ttf");
  font-weight: 700;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-SemiBold.ttf");
  font-weight: 600;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Medium.ttf");
  font-weight: 500;
}

@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Regular.ttf");
  font-weight: 400;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Light.ttf");
  font-weight: 300;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-ExtraLight.ttf");
  font-weight: 200;
}
@font-face{
  font-family: "Lexend";
  src: url("../assets/font/Lexend/Lexend-Thin.ttf");
  font-weight: 100;
}


html, body{
  font-family: 'Encode Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  overflow-x: hidden;
  background: #fff;
}
a, input, button{
  font-family: 'Open Sans', sans-serif;
}