@charset "utf-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import "./global";
@import "./font";
@import "./partials/singleSelect";
@import "./partials/modal";

html {
  scroll-behavior: smooth;
}

/*Warning class*/
.e-warning-color {
  color: $red_old_price;
}

body {
  color: $primary_black;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
h3 {
  font-weight: 300;
  font-size: $size-24;
  line-height: $size-30;
}
h4 {
  font-weight: 300;
  font-size: $size-18;
  line-height: $size-22;
}
h5 {
  font-weight: 300;
  font-size: $size-16;
  line-height: $size-20;
}
h6 {
  font-weight: 300;
  font-size: $size-14;
  line-height: $size-17;
}
p {
  font-weight: normal;
  font-size: $size-14;
  line-height: $size-17;
}
a {
  font-weight: normal;
  font-size: $size-14;
  line-height: $size-17;
  color: $primary_blue !important;
  text-decoration: none !important;
  transition: 0.3s ease;
}
a:hover {
  text-decoration: underline !important;
}

input.e-file-input {
  width: 0 !important;
  height: 0 !important;
  position: absolute !important;
  opacity: 0 !important;
}
.e-file-upload {
  padding: 14px 16px;
  background: linear-gradient(90deg, #e9ecff 2.38%, #f6e7ff 99.05%);
  border: 1px dashed $small_titles_grey;
  box-sizing: border-box;
  border-radius: 24px;
  font-weight: 600;
  font-size: $size-14;
  line-height: $size-20;
  color: $primary_blue;
  min-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0);
  transition: 0.3s ease-in;

  &:hover {
    color: $primary_purple;
    border-color: $primary_purple;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  }
}

/*Common Classes*/
.e-gradient-bg {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 251px;
  background: linear-gradient(90deg, #00c97c 0.41%, #00c97c 140.38%);
}
.e-white-bg-wrapper {
  padding: $size-32;
  width: 100%;
  background: $white;
  border: 1px solid $primary_grey;
  box-sizing: border-box;
  border-radius: 24px;
}
.x-blank-card{
    top: 0;
    left: 0;
    transition: 0.3s ease-in;
}
.e-py-60 {
  padding-top: $size-60;
  padding-bottom: $size-60;
}
.e-pb-248 {
  padding-bottom: 248px;
}
.e-px-32 {
  padding-left: $size-32 !important;
  padding-right: $size-32 !important;
}
.e-py-32 {
  padding-top: $size-32 !important;
  padding-bottom: $size-32 !important;
}
.e-grey-bg {
  background: $primary_grey;
}
.e-gradient-faded-bg {
  background: linear-gradient(90deg, #fcfcfc 2.38%, #e8f1ee 99.05%);
}
.e-max-content-width {
  width: max-content !important;
}
.e-border-bottom {
  border-bottom: 1px solid $primary_grey;
}
.e-border-right {
  border-right: 1px solid $primary_grey;
}
.e-text-link {
  font-weight: 600;
  font-size: $size-16;
  line-height: $size-20;
  text-decoration-line: underline;
  color: $primary_blue;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    color: $primary_purple;
  }
}

/* Icon Indicator Bubble*/
.e-icon-indicator-bubble {
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  border-radius: 4px;
  opacity: 0;
  background-color: $red_old_price;
  transition: 0.35s ease-in;
  pointer-events: none;
  &.e-indicator-bubble-active {
    opacity: 1;
  }
}
.e-img-32 {
  width: 32px;
  height: 32px;
}
.e-green-text {
  color: $text_green;
}
.e-hidden-from-view {
  opacity: 0;
  pointer-events: none;
}
.e-text-muted {
  color: $small_titles_grey !important;
}
.e-cursor-pointer {
  cursor: pointer;
}

/*------ Header ------*/
.e-header {
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  .e-header-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    cursor: pointer;
  }
  .e-header-logo {
    width: 142px;
    cursor: pointer;
  }
  .e-notification-dropdown-wrapper {
    .e-icon-indicator-bubble {
      right: 4px;
    }
  }
}

/*------ Auth ------*/
.e-auth-wrapper {
  width: 100vw;
  height: 100vh;
  background: $page_bg_grey;
  .e-auth-header-wrapper {
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .e-auth-gradient {
    width: 42vw;
    height: 100vh;
    /*background: linear-gradient(90deg, #4660f1 0.41%, #8800dc 140.38%);*/
	background: linear-gradient(90deg, #060606 0.41%, #265416 140.38%);
    .e-illustration-wrapper {
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      pointer-events: none;
      .e-auth-illustration {
        width: 350px;
        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
  .e-auth-content-wrapper {
    width: 58vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    .e-auth-container {
      margin-top: 11vh;
      margin-bottom: 21vh;
      .e-auth-max-width-container {
        width: 100%;
        max-width: 540px;
        .e-fp-back-btn {
          bottom: -20%;
          left: 0;
        }
      }
      .e-auth-form-wrapper {
        background: $white;
        border: 1px solid $primary_grey;
        box-sizing: border-box;
        border-radius: 24px;
        padding: $size-32;
        width: 540px;
        .e-google-signup {
          background-image: url(../assets/images/icons/google-24.svg);
        }
        .e-facebook-signup {
          background-image: url(../assets/images/icons/facebook-24.svg);
        }
        .e-facebook-signup,
        .e-google-signup {
          width: 20px;
          height: 20px;
          background-position: center left;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}

/*------ Footer ------*/
.e-footer {
  width: 100vw;
  background: #183c18;
  padding-top: $size-16;
  padding-bottom: $size-27;
  .e-footer-container {
    color: $white;
    opacity: 1;
    a {
      color: $white !important;
      white-space: nowrap;
    }
    .e-footer-logo-container {
      a {
        text-decoration: none !important;
      }
    }
    h4 {
      font-weight: 500;
      font-size: $size-18;
      line-height: $size-20;
    }
    span {
      font-weight: normal;
      font-size: $size-12;
      line-height: $size-18;
      user-select: none;
      a {
        font-size: inherit;
        font-weight: 600;
      }
    }
  }
}

/* Summary Cards */
.e-card-wrapper {
  background: $white;
  border: 1px solid $primary_grey;
  box-sizing: border-box;
  border-radius: 24px;
}

.e-summary-card {
  background: $white;
  border: 1px solid $primary_grey;
  box-sizing: border-box;
  border-radius: 24px;
  padding: $size-32;
  h6 {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $small_titles_grey;
  }
  h3 {
    font-weight: 500;
  }
}

/*------ Main Body Wrapper------*/
.e-app-body-wrapper {
  width: 100vw;
  min-height: 90vh;
  padding-top: 90px;
  background: #fafafa;
}

/*----- Search Page -----*/
.e-search-page-container {
  .e-back-btn-wrapper {
    top: 0;
    left: $size-16;
  }
  .e-empty-illustration {
    width: 160px;
    height: auto;
  }
  .e-empty-search-illustration-wrapper {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: 0.2s ease;
    background-color: $white;
    &.e-illustration-active {
      opacity: 1;
    }
  }
}

.e-back-btn-wrapper {
  top: 0;
  left: $size-16;
}

/*------Cart Container------*/
.e-cart-page-container {
  .e-cart-wrapper {
    background: $white;
    border: 1px solid $primary_grey;
    box-sizing: border-box;
    border-radius: 24px;
    .e-icon {
      width: 30;
      height: 30;
      object-fit: contain;
    }
    .e-delete-icon {
      cursor: pointer;
      path {
        transition: 0.3s ease;
      }
      &:hover {
        path {
          fill: $primary_purple;
        }
      }
    }
    h5 {
      span {
        font-weight: normal;
        font-size: $size-12;
        line-height: $size-15;
        color: $secondary_grey;
      }
    }
    .e-grey-text {
      font-weight: normal;
      font-size: $size-12;
      line-height: $size-15;
      color: $small_titles_grey;
    }
  }
}
/* Dots animation */
.e-append-dots::after {
  content: " .";
  margin: 0;
  font-size: inherit;
  color: inherit;
  line-height: 180%;
  animation: dotsAnime 2s steps(1, end) infinite;
}
.e-append-dots-delayed::after {
  content: ".";
  margin: 0;
  font-size: inherit;
  color: inherit;
  line-height: 180%;
  animation: dotsAnime 4s steps(1, end) infinite;
}

@keyframes dotsAnime {
  0%,
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    text-shadow: 0.3em 0;
  }
  75%,
  100% {
    text-shadow: 0.3em 0, 0.6em 0;
  }
}

/*-----Checkout Page -----*/
.e-checkout-page {
  .e-checkout-form-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .e-checkout-info-muted {
    font-weight: normal;
    font-size: $size-12;
    line-height: $size-15;
    color: $small_titles_grey;
  }
}

/*----- Dashboard -----*/
.e-dashboard-page {
  .e-donut-chart {
    .apexcharts-legend {
      flex-direction: column;
    }
    .apexcharts-legend-series {
      padding-top: 24px;
    }
    span.apexcharts-legend-text {
      color: $small_titles_grey !important;
      text-transform: uppercase !important;
    }
  }
  .e-line-chart {
    .apexcharts-text {
      text-transform: uppercase !important;
      font-weight: 600;
      font-size: $size-11;
      line-height: $size-14;
      text-align: center;
      color: #454459 !important;
      opacity: 0.5;
    }
  }
}

/* Chart Scroll */
.e-chart-wrapper {
  overflow-x: auto;
  scrollbar-width: thin !important;
  scrollbar-color: #cacaca #f4f4f4;
  &::-webkit-scrollbar {
    display: block !important;
    width: 4px !important ;
    height: 4px !important;
    background-color: #f4f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px !important ;
    background: #cacaca;
  }
}

/*----- Manage Domains Page -----*/
.e-manage-domains-page {
  .e-active-domain {
    .e-domain-gradiant-bg {
      background: linear-gradient(90deg, #fafcfe 2.38%, #f7f7f0 99.05%);
      width: 100%;
      padding-top: $size-40;
      padding-bottom: $size-40;
    }
  }
}

/*----- Domain detail page -----*/
.e-domain-detail-page-container {
  .e-gradient-bg {
    height: 268px;
    background: linear-gradient(90deg, #00c97c 0.41%, #00c97c 140.38%);
  }
  .e-domain-name {
    font-weight: 600;
    font-size: $size-48;
    line-height: $size-60;
    color: $white;
  }
  .e-estimated-value-wrapper {
    color: $white;
    h5 {
      font-weight: 500;
    }
  }
  .e-toggle-wrapper {
    label {
      font-weight: 500;
      font-size: $size-24;
      line-height: $size-30;
      color: $secondary_grey;
    }
  }
}

.e-scan-health-modal {
  .e-append-dots::after {
    content: ".";
    margin: 0;
    font-size: inherit;
    color: inherit;
    line-height: 180%;
    animation: dotsAnime 4s steps(1, end) infinite;
  }
}

/*------ Domain Management Page ------*/
.e-domain-management-container {
  .e-gradient-bg {
    height: 251px;
  }
  p.e-domain-name-title {
    font-size: $size-18;
    line-height: $size-22;
    color: $secondary_grey;
    font-weight: 500;
  }
}

/*------ Reports Page ------*/
.e-reports-page-container {
  .e-gradient-bg {
    height: 318px;
  }
  .e-donut-chart {
    .apexcharts-legend-series {
      padding-top: 24px;
    }
    span.apexcharts-legend-text {
      color: $small_titles_grey !important;
      text-transform: uppercase !important;
    }
    .apexcharts-legend {
      min-width: 200px;
    }
  }
}

/*----- Social Media Page ------*/
.e-social-media-container {
  .e-gradient-bg {
    height: 318px;
  }
  .e-social-media-card {
    text-decoration: none !important;
    cursor: pointer;
    &:hover {
      text-decoration: none !important;
    }
  }
}

/*------ Profile Page ------*/
.e-profile-page-container {
  .e-profile-page-header-wrapper {
    background: linear-gradient(90deg, #00c97c 0.41%, #00c97c 140.38%);
  }
  .e-profile-header {
    h2 {
      font-weight: 600;
      font-size: $size-48;
      line-height: $size-60;
      color: $primary_black;
    }
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
      object-position: center;
      border-radius: 30px;
    }
  }
  .e-card-icon {
    img {
      width: 30px;
      height: 30px;
      object-position: center;
      object-fit: contain;
    }
  }
  .e-logout-wrapper {
    cursor: pointer;
    h6 {
      transition: 0.3s ease-in;
    }
    path {
      transition: 0.3s ease-in;
    }
    &:hover {
      h6 {
        color: $red_old_price;
      }
      path {
        fill: $red_old_price;
      }
    }
  }
}
.e-edit-profile-modal {
  .e-edit-profile-pic {
    width: 48px;
    height: 48px;
    object-fit: contain;
    object-position: center;
    border-radius: 40px;
  }
  .e-file-upload {
    padding: 4px 12px;
    min-width: auto;
  }
}

/*------ Manage Coupons Page ------*/
.e-manage-coupons-page-container {
  .e-gradient-bg {
    height: 251px;
  }
}

/*------ Coupon List Page ------*/
.e-coupon-list-page-container {
  .e-gradient-bg {
    height: 438px;
  }
  .e-plus-icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.12);
    border-radius: 100px;
    transition: 0.35s ease-in;
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
}

.e-invoice-modal {
 .modal-dialog {
    transform: none;
    max-width: 865px !important;
  }
  .page {
    width: 215mm;
    min-height: 100%;
    // padding: 20mm;
    margin: 0mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  .e-print-btn {
    padding: 18px 36px;
    position: relative;
    z-index: 1;
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    transition: 0.3s ease-in;
    width: max-content;
    height: max-content;
    background: #4660f1;
    border: 1px solid #4660f1;
    border-radius: 8px;
    transition: 0.3s ease-in;
  }

  .subpage {
    // padding: 1cm;
    height: 100%;
  }

  @page {
    size: A4;
    margin: 0;
  }
  @media print {
    html,
    body {
      width: 200mm;
      height: 100%;
    }
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  }
}

.e-add-coupon-modal {
  .e-file-upload {
    background: #fff0;
  }
  .e-coupon-upload {
    margin-bottom: 0 !important;
    padding-bottom: $size-26 !important;
  }
  .e-img-loading {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: center;
  }
  .e-uploaded-wrapper {
    overflow: hidden;
    width: calc(100% - 2px);
    .e-cover-uploaded {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .e-upload-new-wrapper {
      top: 0;
      left: 0;
      background-color: #0003;
      transition: 0.3s ease-in;
      z-index: 2;
      span {
        padding: 4px 12px;
        border-radius: 40px;
        color: $primary_blue;
        background: linear-gradient(90deg, #00c97c 0.41%, #00c97c 140.38%);
        border: 1px dashed $small_titles_grey;
        font-weight: 600;
      }
      &:hover {
        background-color: #0006;
      }
    }
  }
}
.e-image-preview-modal {
  .modal-content {
    max-width: 600px;
  }
}

/*------ Templates Page ------*/
.e-templates-page-container {
  .e-gradient-bg {
    height: 251px;
  }
}
/*-------loader---*/
.e-btn-loader span {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}
.e-upload-loader {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}
@keyframes dots {
  0%,
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    text-shadow: 0.5em 0;
  }
  75%,
  100% {
    text-shadow: 0.5em 0, 1em 0;
  }
}
.e-payment-loader {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dotss 1s steps(1, end) infinite;
  }
}
@keyframes dotss {
  0%,
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    text-shadow: 0.2em 0;
  }
  75%,
  100% {
    text-shadow: 0.2em 0, 0.4em 0;
  }
}
.css-26l3qy-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 2 !important;
  box-sizing: border-box;
}
.e-disabled-input{
  background-color: gray!important;
}
.e-coupons-active-tab{
  left: -11px!important;
}
.e-nft-detail-link{
  margin-left: 86px;
}