@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    Web 23
Version:    
Created:    
Use:  
-------------------------------------------------------------------*/

/* --- 1. Colors --- */

$white: #ffffff;
$black: #000000;

$primary_blue:#4660F1;
$primary_purple: #8800DC;
$primary_grey: #F3F4F7;
$primary_black: #11142F;
$secondary_grey: #242631;
$red_old_price:#FF5050;
$light_blue_bg: #F5F9FD;
$light_lime_bg: #F9FDF5;
$light_purple_bg: #F9F5FD;
$light_green_bg: #F2FBF5;
$light_yellow_bg: #FBF9F2;
$light_light_blue_bg: #F2F8FB;
$light_red_bg: #FBF4F2;
$light_pink_bg: #FBF2F8;
$small_titles_grey: #9EA3AA;
$page_bg_grey: #FAFAFA;
$switch_green: #76EE59;
$text_green: #00D248;
$item_highlight_bg: #452EAB;
$delete-red: #EB5757;


/* ---  Size in rem --- */ 
$size-2 : 0.125rem;
$size-4 : 0.250rem;
$size-6 : 0.375rem;
$size-7 : 0.438rem;
$size-8 : 0.500rem;
$size-9 : 0.563rem;
$size-10 : 0.625rem;
$size-11 : 0.688rem;
$size-12 : 0.750rem;
$size-13 : 0.813rem;
$size-14 : 0.875rem;
$size-15 : 0.938rem;
$size-16 : 1.000rem;
$size-17 : 1.063rem;
$size-18 : 1.125rem;
$size-19 : 1.188rem;
$size-20 : 1.250rem;
$size-21 : 1.313rem;
$size-22 : 1.375rem;
$size-23 : 1.438rem;
$size-24 : 1.500rem;
$size-25 : 1.563rem;
$size-26 : 1.625rem;
$size-27 : 1.688rem;
$size-28 : 1.75rem;
$size-29 : 1.813rem;
$size-30 : 1.875rem;
$size-31 : 1.938rem;
$size-32 : 2rem;
$size-33 : 2.063rem;
$size-34 : 2.125rem;
$size-35 : 2.188rem;
$size-36 : 2.25rem;
$size-40 : 2.5rem;
$size-48 : 3rem;
$size-60 : 3.75rem;
$size-80 : 5rem;
$size-300 : 18.75rem;