@charset "utf-8";
@import "./global.scss";

/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {
  
}

@media only screen and (max-width: 1270px) {


}

@media only screen and (max-width: 1200px) {
   .e-auth-wrapper .e-auth-gradient .e-illustration-wrapper .e-auth-illustration.e-signup-illustration{
      width: 300px;
   }
}


@media only screen and (max-width: 1199px) {

}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {

}

@media only screen and (max-width: 992px) {
   .e-auth-wrapper .e-auth-gradient .e-illustration-wrapper .e-auth-illustration.e-signup-illustration{
      width: 230px;
   }
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {
   .e-auth-wrapper{
      .e-auth-gradient{
         display: none;
      }
      .e-auth-content-wrapper{
         width: 100vw;
         .e-auth-container{
            margin-top: 120px;
            margin-bottom: 160px;
         }
      }
      .e-auth-header-wrapper{
         background: linear-gradient(90deg, #4660F1 0.41%, #8800DC 140.38%);
      }
   }
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {
	.desktopClear{ display:none;}
}

@media only screen and (max-width: 380px) {

}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {


}