.css-yk16xz-control, .css-1pahdxg-control{
	background-color: $primary_grey !important;
    border: 1px solid $primary_grey !important;
    box-sizing: border-box !important;
    border-radius: 8px !important;
    cursor: pointer !important;
    padding: $size-8 !important;
    outline: none !important; 
    box-shadow: none !important;
    font-weight: 500 !important;
    font-size: $size-14 !important;
    line-height: $size-17 !important;
    color: $primary_black !important;
    .css-1wa3eu0-placeholder, .css-g1d714-ValueContainer{
    	font-weight: 500 !important;
        font-size: $size-14 !important;
        line-height: $size-17 !important;
        color: $primary_black !important;
        padding:0 !important;
    }
  .css-1okebmr-indicatorSeparator{
  	display: none !important;
  }
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
    background-image: url(../../assets/images/icons/arrow-down-black.svg)!important;
    background-size: 16px;
    background-repeat: no-repeat!important;
    background-position-y: center!important; 
    svg{
      display: none!important;
    }
  }
}
.css-26l3qy-menu{
  *{
    font-size: $size-14 !important;
    font-weight: 400 !important;
  }
}
.e-grey-bg, .e-gradient-faded-bg{
  .css-yk16xz-control, .css-1pahdxg-control{
    background: $white !important;
    border: 1px solid #ECECEC !important;
  }
}

.e-input-wrap{
  position: relative;
  padding: 0!important;
  margin: $size-26 0;
  .css-yk16xz-control, .css-1pahdxg-control{
    background: #fff0 !important;
    border: 1px solid #CACACA !important;
    border-radius: 8px !important;
    padding: 0.7rem $size-12 !important;
    font-size: $size-14 !important;
    line-height: $size-20 !important;
    letter-spacing: 0.25px !important;
    color: $secondary_grey !important;
    .css-1wa3eu0-placeholder{
      color: $small_titles_grey !important;
    }
  }
  .css-1pahdxg-control{
    background: $light_blue_bg !important;
    border: 1px solid $primary_blue !important;
    color: $secondary_grey !important;
  }
  label{
    position: absolute;
    top: -$size-26;
    left: 0;
    background: #fff0;
    transition: all .3s ease-out;
    font-weight: 500;
    font-size: $size-14;
    line-height: $size-17;
    color: $secondary_grey;
    pointer-events: none;
  }
}