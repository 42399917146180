/*--General style override for BS modals--*/

.modal-content{
    background: $page_bg_grey;
    border: 1px solid $primary_grey;
    box-sizing: border-box;
    border-radius: 24px;
    color: $primary_black;
}
.modal-backdrop{
    background-color: #ccc;
}
.modal-header{
    h5{
        font-weight: 600;
        font-size: $size-18;
        line-height: $size-22;
    }
}
.modal-header, .modal-footer, .modal-body{
    border: none;
    padding: 0;
}

.e-close-btn-wrapper{
    top: $size-16;
    right: $size-16;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: $white;
    border: 1px solid $primary_grey;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    transition: 0.3s ease-in;
    cursor: pointer;
    z-index: 1;
    path{
        transition: 0.3s ease-in;
    }
    &:hover{
        box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);
        path{
            fill: $red_old_price;
        }
    }
}

/*---- Subscription Modal ----*/
.e-subscription-modal{
    .modal-dialog{
        width: 920px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- Add Records Modal ----*/
.e-add-record-modal{
    .modal-dialog{
        width: 930px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- KYC Modal ----*/
.e-add-kyc-modal{
    .modal-dialog{
        width: 634px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- Profile Detail Modal ----*/
.e-edit-profile-modal{
    .modal-dialog{
        width: 634px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- Add Coupon Modal ----*/
.e-add-coupon-modal{
    .modal-dialog{
        width: 634px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- Redirection Url Modal ----*/
.e-edit-url-modal{
    .modal-dialog{
        width: 480px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
}

/*---- Confirm Delete Modal ----*/
.e-confirm-delete-modal{
    .modal-dialog{
        width: 480px;
        max-width: 90vw;
    }
    .modal-content{
        padding: 32px;
    }
    .e-delete-illustration{
        width: 109px;
        height: auto;
    }
}

/*---- Scan Health Modal ----*/
.e-scan-health-modal{
    .modal-dialog{
        width: 480px;
        max-width: 90vw;
    }
    .modal-content{
        background: #FFFFFF;
        padding: 32px;
        min-height: 300px;
    }
    .e-scan-loader{
        opacity: 1;
        transition: 0.35s ease;
        &.e-hide-scan-loader{
            opacity: 0;
        }
    }
    .e-health-badge-container{
        width: 120px;
        height: 120px;
    }
    .e-scan-result{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.35s ease;
        .e-indicator-halo-bottom{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            background: #E5FFE7;
            border-radius: 80px;
            transition: width 0.35s ease 0.3s, height 0.35s ease 0.3s;
        }
        .e-indicator-halo-top{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            background: #CAFFCE;
            border-radius: 80px;
            transition: width 0.35s ease 0.3s, height 0.35s ease 0.3s;
        }
        .e-health-icon{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            object-fit: contain;
            object-position: center;
            transition: width 0.35s ease 0.3s, height 0.35s ease 0.3s;
        }
        &.e-show-result{
            opacity: 1;
            .e-indicator-halo-bottom{
                width: 120px;
                height: 120px;
            }
            .e-indicator-halo-top{
                width: 86.25px;
                height: 86.25px;
            }
            .e-health-icon{
                width: 72px;
                height: 72px;
            }
        }
    }

}
/*---- Scan Health Modal ----*/
.e-resolve-modal{
    .modal-dialog{
        width: 480px;
        max-width: 90vw;
    }
    .modal-content{
        background: #FFFFFF;
        padding: 32px;
        min-height: 356px;
    }
    .e-resolve-loader{
        opacity: 1;
        transition: 0.35s ease;
        .e-resolving-gif{
            width: 120px;
            height: 120px;
            object-fit: contain;
        }
        &.e-hide-resolve-loader{
            opacity: 0;
        }
    }
    .e-health-badge-container{
        width: 120px;
        height: 120px;
    }
    .e-resolve-result{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        height: 10px;
        overflow: hidden;
        transition: opacity 0.35s ease, height 0.35s ease;
        .e-indicator-halo-bottom{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            background: #E5FFE7;
            border-radius: 80px;
            transition: width 0.35s ease 0.4s, height 0.35s ease 0.4s;
        }
        .e-indicator-halo-top{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            background: #CAFFCE;
            border-radius: 80px;
            transition: width 0.35s ease 0.4s, height 0.35s ease 0.4s;
        }
        .e-health-icon{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0px;
            height: 0px;
            object-fit: contain;
            object-position: center;
            transition: width 0.35s ease 0.4s, height 0.35s ease 0.4s;
        }
        &.e-show-result{
            opacity: 1;
            height: 100%;
            .e-indicator-halo-bottom{
                width: 120px;
                height: 120px;
            }
            .e-indicator-halo-top{
                width: 86.25px;
                height: 86.25px;
            }
            .e-health-icon{
                width: 72px;
                height: 72px;
            }
        }
    }

}

/*-----payment modal ---------*/
.e-payment-modal{
    .modal-dialog{
        width: 480px;
        max-width: 90vw;
    }
    .modal-content{
        background: #FFFFFF;
        padding: 32px;
        min-height: 356px;
    }
    .e-resolve-loader{
        opacity: 1;
        transition: 0.35s ease;
        .e-resolving-gif{
            width: 120px;
            height: 120px;
            object-fit: contain;
        }
        &.e-hide-resolve-loader{
            opacity: 0;
        }
    }
    .e-health-badge-container{
        width: 120px;
        height: 120px;
    }
    .e-resolve-result{
        top: 20%;
        left: 50%;
        // transform: translate(-50%, -50%);
        height: 60px;
        // overflow: hidden;
        transition: opacity 0.35s ease, height 0.35s ease;
        .e-health-icon{
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: contain;
            object-position: center;
            transition: width 0.35s ease 0.4s, height 0.35s ease 0.4s;
        }
        &.e-show-result{
            opacity: 1;
            height: 100%;
            .e-indicator-halo-bottom{
                width: 120px;
                height: 120px;
            }
            .e-indicator-halo-top{
                width: 86.25px;
                height: 86.25px;
            }
            .e-health-icon{
                width: 72px;
                height: 72px;
            }
        }
    }

}